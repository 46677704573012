<template>
  <v-card flat color="transparent" :min-height="minHeight ? minHeight : $vuetify.breakpoint.smAndDown ? 280 : 390">
    <v-row class="ma-0" justify="space-between" no-gutters>
      <v-col v-if="title">
        <v-card-title class="pa-0 text-h5 font-weight-bold word-break">{{ title }}</v-card-title>
      </v-col>
      <v-col v-if="linkUrl">
        <v-card-text class="text-right pa-0 pr-8 text-subtitle-1"><a :href="linkUrl" class="d-inline-block ml-auto">{{ link }}</a></v-card-text>
      </v-col>
    </v-row>
    <v-row class="ma-0 pt-2" justify="space-between" no-gutters v-if="subtitle">
      <v-col>
        <v-card-subtitle class="pa-0 text-body-2 font-weight-bold word-break-normal">{{ subtitle }}</v-card-subtitle>
      </v-col>
    </v-row>
    <v-row v-if="altItemText" :class="$vuetify.breakpoint.smAndDown ? 'mb-7' : 'mb-10'" dense no-gutters>
      <v-col class="d-flex justify-center align-center mx-auto" style="min-height: 250px">
        <span v-html="altItemText"></span>
      </v-col>
    </v-row>
    <v-row class="ma-0" v-else>
        <v-sheet
            :max-width="layout === 'row' ? '100%' : $vuetify.breakpoint.smAndDown ? '95%' : '100%'"
            color="transparent"
        >
          <div ref="sliderA"
               class="pt-4 slide-content"
               :class="$vuetify.breakpoint.smAndDown ? 'mb-7' : 'mb-10'"
          >
            <template v-if="$vuetify.breakpoint.smAndUp && items.length">
              <v-img v-if="!isShowGoRightElement && isVisibleSlider"  @click="moveRight('sliderA')" class="slide-to-right" src="/assets/images/index/right-arrow.svg" style="cursor: pointer"></v-img>
              <v-img v-if="isShowGoRightElement && isVisibleSlider" @click="moveElementTop('sliderA')" class="slide-to-right" src="/assets/images/index/double-right-arrow.svg" style="cursor: pointer"></v-img>
              <v-img v-if="isShowGoLeftElement" @click="moveLeft('sliderA')" class="slide-to-left" src="/assets/images/index/left-arrow.svg" style="cursor: pointer"></v-img>
            </template>
            <template v-for="(item,n) in items">
              <div class="slide-item">
                  <v-card
                      flat
                      color="transparent"
                  >
                  <v-scale-transition>
                    <vb-item
                      :item="item"
                      :is_price_label_size_x_small="true"
                      @loaded="handleLoaded"
                      :layout="layout"
                      :fixed_width="fixedWidth"
                    />
                  </v-scale-transition>
                    <slot name="actionButton" :catalogId="item.vs_catalog_id" />
                </v-card>
              </div>
            </template>
          </div>
        </v-sheet>
    </v-row>
  </v-card>
</template>

<script>
import VbItem from "../../molecules/vb-item.vue";

export default {
  name: "slide-item",
  components: {
    VbItem,
  },
  props: {
    title: {type: String, required: false, default: null},
    subtitle: {type: String, required: false, default: null},
    link: {type: String, required: false, default: "もっと見る"},
    linkUrl: {type: String, required: false, default: null},
    items: {type: Array, required: false, default: []},
    altItemText: {type: String, required: false, default: null},
    minHeight: {type: String, required: false, default: null},
    layout: {type: String, required: false, default: 'column'},
    fixedWidth: {type: String, required: false, default: null},
  },
  data () {
    return {
      scrollPosition: 0,
      scrollWidth: 1,
      clientWidth: 0,
      sliderElement: null,
    }
  },
  methods: {
    handleLoaded(){
      this.handleResize();
    },
    next(ref) {
      this.$refs[ref].scrollOffset = 0;
    },
    moveRight(ref){
      const element = this.$refs[ref];
      const clientWidth = element.clientWidth;
      const nowScrollPotion = element.scrollLeft;

          element.scrollTo({left: nowScrollPotion + clientWidth*0.9, behavior: "smooth"});
    },
    moveLeft(ref){
      const element = this.$refs[ref];
      const clientWidth = element.clientWidth;
      const nowScrollPotion = element.scrollLeft;

      element.scrollTo({left: nowScrollPotion - clientWidth*0.9, behavior: "smooth"});
    },
    moveElementTop(ref){
      const element = this.$refs[ref];

      element.scrollTo({left: 0, behavior: "smooth"});
    },
    handleResize () {
      if (this.$refs.sliderA) {
        this.setScrollPosition();
      }
    },
    setScrollPosition() {
      if (this.items.length) {
        const element = this.sliderElement;
        this.scrollPosition = element.scrollLeft;
        this.scrollWidth = element.scrollWidth;
        this.clientWidth = element.clientWidth;
      }
    }
  },
  computed: {
    hasNext() {
      return (ref) => {
        if(this.$refs[ref]) return this.$refs[ref].hasNext;
      }
    },
    isShowGoLeftElement() {
      return this.scrollPosition == 0 ? false : true;
    },
    isShowGoRightElement() {
      return this.scrollPosition + this.clientWidth >= this.scrollWidth
    },
    isVisibleSlider() {
      return this.clientWidth < this.scrollWidth;
    },
  },
  mounted(){
    this.sliderElement = this.$refs.sliderA;
    window.addEventListener('resize', this.handleResize);
    this.setScrollPosition();
    this.sliderElement.onscroll = () => {
      this.setScrollPosition();
    }
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped lang="scss">
.slide-content {
  display:flex;
  overflow-x:auto;
  overflow-y: hidden;
  align-items: flex-end;
  .slide-to-left {
    overflow-x: auto;
    z-index: 1;
    top: 38%;
    left: 23px;
    right: auto;
    position: absolute;
  }
  .slide-to-right {
    overflow-x: auto;
    z-index: 1;
    top: 38%;
    left: auto;
    right: 44px;
    position: absolute;
  }
}
.slide-item {
  position: relative;
  flex: 0 0 auto;
}
.word-break {
  word-break: keep-all;
}
.word-break-normal {
  word-break: normal;
}
::v-deep {
  .v-slide-group {
    &__prev,
    &__next,
    .slide-back-to-top {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    &__prev {
      left: 80px;
    }
    &__prev,
    .slide-to-left{
      top: -15px;
    }
    &__next,
    .slide-back-to-top {
      right: 108px;
      top: -30px;
      margin: auto;
    }
    &__next,
    .slide-to-right{
      top: -15px;
    }
    &__prev--disabled,
    &__next--disabled {
      display: none;
    }
    &__content {
      align-items: end;
      padding-right: 108px;
    }
  }
}

</style>